<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          size="small"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          value-format="timestamp"
          v-model="dateRange"
          type="year"
          placeholder="统计年份"
        />
        <el-button type="primary" size="small" class="vd_export" @click="getDataList()">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right"> 刷新 </el-button>
        <el-button type="success" size="small" class="vd_export" @click="exportExcel()"> 导出Excel </el-button>
      </div>
      <DynamicUTable
        ref="partMultiTable"
        :tableData="tableData"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :page-size="50"
        :need-pagination="false"
        :need-fixed-height="true"
        :need-search="true"
        :need-check-box="false"
        :need-all-column-summary="true"
        :showSummary="true"
        :summariesColumns="['jan', 'feb', 'mar', 'apr', 'may', 'june', 'july', 'aug', 'sept', 'oct', 'nov', 'dece']"
        @getTableData="getDataList"
      >
        <template v-slot:header-search-prod_category="{ searchForm }">
          <el-cascader
            size="mini"
            v-model="searchForm.prod_category"
            placeholder="请选择"
            :options="prodCategoryOptions"
            clearable
            filterable
            :props="{ emitPath: false }"
            @change="getDataList"
            @close="getDataList"
          ></el-cascader>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { keepNumber } from '@assets/js/regExUtil';
import { downloadFile } from '@api/public';
import { categorySalesStatisticsTableProperties } from '@/views/SalesManagement/SconManage/scon';
import { prodCategoryOptions } from '@/views/MessageManagement/ProdManage/TabChild/ProdDetails';
import { sconAPI } from '@api/modules/scon';

export default {
  name: 'CategorySalesStatistics',
  components: {
    DynamicUTable
  },
  data() {
    return {
      dateRange: null,
      tableProperties: cloneDeep(categorySalesStatisticsTableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      prodCategoryOptions: cloneDeep(prodCategoryOptions)
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    keepNumber,
    initData() {
      this.getDataList();
      this.changeTableProperties();
    },
    async changeTableProperties() {
      // this.tableProperties.find(({ label }) => label === '销售部门').options = await getDept(['业务', '电商'], 'default');
    },
    //获取数据
    async getDataList() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.partMultiTable.searchForm);
      searchForm.yearTime = this.dateRange;
      await sconAPI.getSconProdTotalStatistics(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    //跨分页导出excel
    exportExcel() {
      let requestBody = { type: 1, ...this.$refs.partMultiTable.searchForm, yearTime: this.dateRange };
      sconAPI.exportMonthSconProdTotalStatistics(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '.xlsx' });
      });
    },
    //刷新
    buttonRefresh() {
      this.$refs.partMultiTable.resetFields();
      this.dateRange = null;
      this.getDataList();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
